<template>
  <div
    id="main-footer"
    class="footer-top"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 footer-links">
          <h4>{{ dataConfig.name }}</h4>
          <div class="mb-1">
            <i class="bi bi-geo-alt mr-1"></i>{{ dataConfig.address }}
          </div>
          <div class="mb-1">
            <i class="bi bi-telephone mr-1"></i> {{ dataConfig.phoneNumber }}
          </div>
          <div>
            <i class="bi bi-envelope mr-1"></i>{{ dataConfig.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import store from '../store'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
  },
  data() {
    return {
      tokenCheck: localStorage.getItem('accessToken'),
      roleUser: null,
      userData: this.$userData(),
    }
  },
  computed: {
    ...mapGetters('app', ['dataConfig']),
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home' })
      this.$router.go()
    },
  },
}
</script>

<style lang="scss">

#main-footer {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "../assets/vendor/aos/aos.css";
@import "../assets/css/style.css";
@import "../assets/vendor/animate.css/animate.min.css";
@import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
</style>
