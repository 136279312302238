/* eslint-disable global-require */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  // Lấy thông tin cài dặt hệ thống
  async getInfoSetting() {
    try {
      const { data } = await axiosApiInstance.get('/Home/configuration')
      return data
    } catch (err) {
      return err
    }
  },
  // Lấy danh sách kiểm định viên

  async getInspectorList(payload) {
    try {
      const { data } = await axiosApiInstance.get('/Home/inspectors', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },

  // hrefDistrict: 'http://ldtbxh.hatinh.gov.vn/',
  // hrefDistrict: 'http://soldtbxh.thainguyen.gov.vn/',
  // hrefDistrict: 'http://sldtbxh.daknong.gov.vn/',
  // hrefDistrict: 'https://sldtbxh.daklak.gov.vn/',
  // hrefDistrict: 'https://binhphuoc.gov.vn/vi/sldtbxh/',
  hrefDistrict: 'https://sldtbxh.tiengiang.gov.vn/',

  imgDefaultSquare: require('@/assets/images/banner/tiengiang/upgrade.png'),
  imgDefault: require('@/assets/images/banner/tiengiang/news.png'),
  sideImglogo: require('@/assets/images/pages/tiengiang/logoWhite.svg'),
  province: 'Tiền Giang',

}
